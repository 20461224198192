import { useEffect, useState } from 'react';
import { Country, Region } from 'types';
import axios from 'utils/axios';

const useGetCountryMaster = (): {
  countries: Country[];
  regions: Region[];
} => {
  const [countries, setCountries] = useState<Country[]>([]);
  const [regions, setRegions] = useState<Region[]>([]);

  // 居住国マスター取得
  useEffect(() => {
    const countriesReq = async () => {
      const res = await axios.get('/api/company/master/countries', {});
      if (res && res.status === 200 && Array.isArray(res.data)) {
        const countriesData = res.data.map((country: any) => ({
          id: String(country.id),
          name: country.name,
        }));

        setCountries(countriesData);

        const regionsData = res.data
          .map((country) =>
            country.detail.map((d: any) => ({
              id: String(d.id),
              name: d.name,
              countryId: String(country.id),
            })),
          )
          .flat();

        setRegions(regionsData);
      }
    };

    if (countries.length === 0) countriesReq();
  });

  return {
    countries,
    regions,
  };
};

export default useGetCountryMaster;

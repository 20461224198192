import { FC, useState } from 'react';
import { SubmitHandler, useWatch } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { SearchJobFormData } from 'types';
import useGetCountryMaster from 'hooks/Master/useGetCountryMaster';
import useFormSearchJob from 'hooks/SearchJob/useFormSearchJob';
import { isIn, required, validate } from 'utils/validation';
import SelectDropdown from 'components/parts/SelectDropdown';
import styles from './SearchJob.module.scss';

type Props = {
  onSubmit: SubmitHandler<SearchJobFormData>;
  hasSubColumns?: boolean;
};

const SearchJob: FC<Props> = ({ onSubmit, hasSubColumns }) => {
  const location = useLocation();

  const { register, handleSubmit, control, errors } = useFormSearchJob(location.state);
  const watchCountryId = useWatch({ control, name: 'countryId' });
  const [regionIdUpdate, setRegionIdUpdate] = useState<{ fn: any }>({ fn: undefined });
  const regionIdUpdateRef = (funcUpdate: any) => {
    if (regionIdUpdate.fn) return;

    setRegionIdUpdate({ fn: funcUpdate });
  };

  // マスター情報取得
  const countryMaster = useGetCountryMaster();
  type Regions = {
    [key: string]: Array<any>;
  };
  const regionData: Regions = {};
  countryMaster.countries.forEach((country) => {
    regionData[country.id] = countryMaster.regions.filter((r) => r.countryId === country.id);
  });

  return (
    <form className={styles.searchJob} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.main}>
        <div className={styles.spLine}>
          <div className={styles.countryCont}>
            {countryMaster.countries.length > 0 ? (
              <SelectDropdown
                className={styles.country}
                options={countryMaster.countries}
                control={control}
                useFormRegisterReturn={register('countryId', {
                  onChange: () => {
                    if (regionIdUpdate.fn) {
                      regionIdUpdate.fn('');
                    }
                  },
                  validate: {
                    format: (value) =>
                      validate(
                        isIn(
                          value,
                          countryMaster.countries.map((e) => e.id),
                        ),
                        '地域',
                      ),
                  },
                })}
              />
            ) : (
              <CircularProgress size={40} />
            )}
            <p className={styles.errorMessage}>{errors?.countryId?.message}</p>
          </div>
          <div className={styles.regionCont}>
            {watchCountryId !== '' && regionData[watchCountryId] && regionData[watchCountryId].length > 0 ? (
              <SelectDropdown
                className={`${styles.region} ${errors?.regionId?.message ? styles.errorForm : ''}`}
                options={[{ id: '', name: '勤務地' }].concat(regionData[watchCountryId])}
                control={control}
                useFormRegisterReturn={register('regionId', {
                  validate: {
                    format: (value) =>
                      validate(
                        isIn(
                          value,
                          [{ id: '', name: '勤務地' }].concat(regionData[watchCountryId]).map((e) => e.id),
                        ),
                        '勤務地',
                      ),
                  },
                })}
                updateRef={regionIdUpdateRef}
              />
            ) : (
              <CircularProgress size={40} />
            )}
            <p className={styles.errorMessage}>{errors?.regionId?.message}</p>
          </div>
        </div>
        <div className={styles.spLine} style={{ width: '100%' }}>
          <div className={styles.freeword}>
            <input className="" type="text" placeholder="求人、企業を検索" {...register('freeword', {})} />
          </div>
          <button type="submit" className={styles.submit}>
            検索
          </button>
        </div>
      </div>
      {hasSubColumns && (
        <>
          <div className={`${styles.left} ${styles.isPC}`}>
            <div className={`${styles.periodCont} ${styles.inputGroup}`}>
              <label>掲載日</label>
              <SelectDropdown
                className={`${styles.period} ${errors?.period?.message ? styles.errorForm : ''}`}
                style="red"
                options={[
                  { id: '1', name: '24h' },
                  { id: '2', name: '3日以内' },
                  { id: '3', name: '7日以内' },
                  { id: '4', name: '14日以内' },
                  { id: '', name: 'すべて' },
                ]}
                control={control}
                useFormRegisterReturn={register('period', {
                  validate: {
                    format: (value) => validate(isIn(value, ['1', '2', '3', '4', '']), '掲載日'),
                  },
                })}
              />
            </div>
            <div className={`${styles.salaryCont} ${styles.inputGroup}`}>
              <label>想定年収</label>
              <SelectDropdown
                className={`${styles.salary} ${errors?.salary?.message ? styles.errorForm : ''}`}
                style="red"
                options={[
                  { id: '1', name: '300万円～' },
                  { id: '2', name: '400万円～' },
                  { id: '3', name: '500万円～' },
                  { id: '4', name: '600万円～' },
                  { id: '5', name: '700万円～' },
                  { id: '6', name: '800万円～' },
                  { id: '7', name: '900万円～' },
                  { id: '8', name: '1000万円～' },
                ]}
                control={control}
                useFormRegisterReturn={register('salary', {
                  required: required('想定年収'),
                  validate: {
                    format: (value) => validate(isIn(value, ['1', '2', '3', '4', '5', '6', '7', '8']), '想定年収'),
                  },
                })}
              />
            </div>
          </div>
          <div className={`${styles.spLine} ${styles.isSP}`}>
            <SelectDropdown
              className={`${styles.periodSP} ${errors?.period?.message ? styles.errorForm : ''}`}
              style="red"
              options={[
                { id: '', name: '掲載日' },
                { id: '1', name: '24h' },
                { id: '2', name: '3日以内' },
                { id: '3', name: '7日以内' },
                { id: '4', name: '14日以内' },
              ]}
              control={control}
              useFormRegisterReturn={register('period', {
                validate: {
                  format: (value) => validate(isIn(value, ['', '1', '2', '3', '4']), '掲載日'),
                },
              })}
            />
            <SelectDropdown
              className={`${styles.salarySP} ${errors?.salary?.message ? styles.errorForm : ''}`}
              style="red"
              options={[
                { id: '', name: '想定年収' },
                { id: '1', name: '300万円～' },
                { id: '2', name: '400万円～' },
                { id: '3', name: '500万円～' },
                { id: '4', name: '600万円～' },
                { id: '5', name: '700万円～' },
                { id: '6', name: '800万円～' },
                { id: '7', name: '900万円～' },
                { id: '8', name: '1000万円～' },
              ]}
              control={control}
              useFormRegisterReturn={register('salary', {
                required: required('想定年収'),
                validate: {
                  format: (value) => validate(isIn(value, ['', '1', '2', '3', '4', '5', '6', '7', '8']), '想定年収'),
                },
              })}
            />
          </div>
        </>
      )}
    </form>
  );
};

SearchJob.defaultProps = {
  hasSubColumns: false,
};

export default SearchJob;

import { FC, useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { getCookie, setCookie } from 'typescript-cookie';
import { nl2br } from 'utils/common';
import axios from 'utils/axios';
import { getToken } from 'utils/token';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import 'swiper/swiper.scss';
import { CircularProgress } from '@mui/material';
import { JobData, JobSummary } from 'types';
import getJobsById from 'requests/getJobsById';
import useAuth from 'hooks/useAuth';
import useScrollTop from 'hooks/useScrollTop';
import PageTop from 'components/parts/PageTop';
import JobList from 'components/parts/JobList';
import styles from './JobDetail.module.scss';

const JobDetail: FC = () => {
  const request = useParams<{ id: string }>();
  const navigate = useNavigate();

  const auth = useAuth();
  if (auth.id !== '' && !auth.isValid) navigate('/mypage/edit');

  SwiperCore.use([Autoplay]);
  if (Number.isNaN(request.id)) navigate('/jobs');
  useScrollTop();

  useEffect(() => {
    const visitedOld = getCookie('visited') ?? '';
    let visited = visitedOld === '' ? [] : visitedOld.split(',');
    const id = String(request.id);
    visited = visited.filter(function f(v) {
      return v !== id;
    });
    visited.unshift(id);
    setCookie('visited', visited.join(','));
  }, [request]);

  const [visited, setVisited] = useState<JobSummary[]>([]);
  const [visitedLoadingForPrepare, setVisitedLoadingForPrepare] = useState(true);
  useEffect(() => {
    const visitedIds = getCookie('visited') ?? '';
    const visitedAdsIds = getCookie('visitedAds') ?? '';
    getJobsById(visitedIds, visitedAdsIds).then((response) => {
      setVisited(response.slice(0, 9));
      setVisitedLoadingForPrepare(false);
    });
  }, []);

  const [job, setJob] = useState<JobData>();
  const [loadingForPrepare, setLoadingForPrepare] = useState(true);
  useEffect(() => {
    const req = async () => {
      const res = await axios.get(`/api/v18/jobs/${request.id}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res && res.status === 200 && 'data' in res && res.data.body) {
        setJob({
          title: res.data.title,
          employeeType: res.data.job_working_conditions.employee_type,
          body: res.data.body,
          occupations: Array.from(res.data.occupations, (o: { id: number; name: string }) => o.name),
          industries: Array.from(res.data.industries, (o: { id: number; name: string }) => o.name),
          content: res.data.content,
          workTime: res.data.job_working_conditions.work_time,
          holiday: res.data.job_working_conditions.holiday,
          welfare: Array.from(res.data.welfare, (o: { id: number; name: string }) => o.name),
          /* eslint-disable-next-line */
          images: Array.from(res.data.images, (o: { id: number; file_path: string }) => o.file_path),
          company: res.data.company,
          salary: res.data.job_working_conditions.salary.replaceAll('万', '').split('〜'),
          condition: res.data.job_requirements.condition,
          welcome: res.data.job_requirements.welcome,
          want: res.data.job_requirements.want,
          locations: Array.from(res.data.locations, (o: { id: number; name: string }) => o.name),
          access: res.data.job_working_conditions.access,
          other: res.data.job_working_conditions.other,
        });
      } else {
        navigate('/jobs');
      }
    };

    req().then(() => {
      setLoadingForPrepare(false);
    });
  });

  const processing = useRef(false);
  const { handleSubmit } = useForm();
  const onSubmitApply = async () => {
    // 多重クリック禁止
    if (processing.current) return;
    // 処理中フラグを立てる
    processing.current = true;

    const token = getToken();
    try {
      await axios.get(`/api/v18/jobs/${request.id}/apply`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (e: any) {
      switch (e.response.status) {
        case 401:
          navigate('/login');
          break;
        case 405:
          processing.current = false;
          alert('非有効登録での応募は許可されていません。');
          break;
        default:
          processing.current = false;
          alert('不明なエラーが発生しました。お時間をおいて再度お試しください。');
          break;
      }
      return;
    }

    navigate(`/messages?id=${job?.company.id}`);
  };

  return (
    <>
      {processing.current && (
        <section className={styles.processing}>
          <CircularProgress color="inherit" size={80} />
        </section>
      )}
      {!loadingForPrepare ? (
        <section
          className={styles.header}
          style={job?.images ? { backgroundImage: `url(${job.images[0]})` } : undefined}
        >
          <h1>{job?.title}</h1>
          <div className={styles.subCont}>
            <div className={styles.salary}>
              {job?.salary[0]}-{job?.salary[1]}
              <span className={styles.unit}> 万円</span>
            </div>
            <form onSubmit={handleSubmit(onSubmitApply)} noValidate>
              <button type="submit" disabled={processing.current}>
                応募する
              </button>
            </form>
          </div>
        </section>
      ) : (
        <section className={`${styles.header} ${styles.loadingCircle}`}>
          <CircularProgress />
        </section>
      )}

      <main id={styles.main}>
        <section>
          <h2>求人詳細</h2>
          {!loadingForPrepare ? (
            <>
              {job?.images && (
                <Swiper
                  className={styles.jobImages}
                  slidesPerView={Math.min(job.images.length, 1.5)}
                  loop
                  loopedSlides={Math.min(job.images.length, 2)}
                  centeredSlides
                  initialSlide={1}
                  autoplay={{
                    delay: 3000,
                    pauseOnMouseEnter: true,
                    disableOnInteraction: false,
                  }}
                  spaceBetween={8}
                >
                  {job.images.map((image) => (
                    <SwiperSlide key={`job-img-${image}}`}>
                      <div className={styles.card} style={{ backgroundImage: `url(${image})` }}>
                        <></>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              )}
              <div>
                <h3>求人概要</h3>
                <dl>
                  {job?.employeeType && (
                    <>
                      <dt>雇用区分</dt>
                      <dd>{job.employeeType}</dd>
                    </>
                  )}
                  {job?.body && (
                    <>
                      <dt>募集背景</dt>
                      <dd>{nl2br(job.body)}</dd>
                    </>
                  )}
                  {job && job.occupations.length > 0 && (
                    <>
                      <dt>職種</dt>
                      <dd>{nl2br(job.occupations.join('\n'))}</dd>
                    </>
                  )}
                  {job && job.industries.length > 0 && (
                    <>
                      <dt>業界</dt>
                      <dd>{nl2br(job.industries.join('\n'))}</dd>
                    </>
                  )}
                  {job?.content && (
                    <>
                      <dt>業務内容</dt>
                      <dd>{nl2br(job.content)}</dd>
                    </>
                  )}
                  {job?.condition && (
                    <>
                      <dt>必須条件</dt>
                      <dd>{nl2br(job.condition)}</dd>
                    </>
                  )}
                  {job?.welcome && (
                    <>
                      <dt>歓迎条件</dt>
                      <dd>{nl2br(job.welcome)}</dd>
                    </>
                  )}
                  {job?.want && (
                    <>
                      <dt>求める人物像</dt>
                      <dd>{nl2br(job.want)}</dd>
                    </>
                  )}
                  {job && job.locations.length && (
                    <>
                      <dt>勤務地</dt>
                      <dd>{nl2br(job.locations.join('\n'))}</dd>
                    </>
                  )}
                  {job?.access && (
                    <>
                      <dt>アクセス</dt>
                      <dd>{nl2br(job.access)}</dd>
                    </>
                  )}
                  {job?.workTime && (
                    <>
                      <dt>勤務時間</dt>
                      <dd>{job.workTime}</dd>
                    </>
                  )}
                  {job?.holiday && (
                    <>
                      <dt>休日・休暇</dt>
                      <dd>{nl2br(job.holiday)}</dd>
                    </>
                  )}
                  {job && job.welfare.length > 0 && (
                    <>
                      <dt>福利厚生</dt>
                      <dd>{nl2br(job.welfare.join('\n'))}</dd>
                    </>
                  )}
                  {job?.other && (
                    <>
                      <dt>備考・PR</dt>
                      <dd>{nl2br(job.other)}</dd>
                    </>
                  )}
                </dl>
              </div>
              <div>
                <h3>企業概要</h3>
                <dl>
                  {job?.company?.name && (
                    <>
                      <dt>企業名</dt>
                      <dd>{job.company.name}</dd>
                    </>
                  )}
                  {job?.company?.capital && (
                    <>
                      <dt>資本金</dt>
                      <dd>{job.company.capital} 万円</dd>
                    </>
                  )}
                  {job?.company?.address && (
                    <>
                      <dt>本社所在地</dt>
                      <dd>{job.company.address}</dd>
                    </>
                  )}
                  {job?.company?.content && (
                    <>
                      <dt>企業説明</dt>
                      <dd>{nl2br(job.company.content)}</dd>
                    </>
                  )}
                </dl>
              </div>
            </>
          ) : (
            <div className={styles.loadingCircle}>
              <CircularProgress />
            </div>
          )}
        </section>

        <section>
          <h2>閲覧した求人情報</h2>
          <JobList jobs={visited} loadingForPrepare={visitedLoadingForPrepare} grid />
        </section>

        <PageTop />
      </main>
      <footer className="l-footer" />
    </>
  );
};

export default JobDetail;

import { FC, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import { JobSummary, SearchJobFormData } from 'types';
import getJobs from 'requests/getJobs';
import { CircularProgress } from '@mui/material';
import JobListSection from 'components/parts/JobList';
import SearchJob from 'components/parts/SearchJob';
import PageTop from 'components/parts/PageTop';
import styles from './JobList.module.scss';

const JobList: FC = () => {
  const location = useLocation();

  const [jobs, setJobs] = useState<JobSummary[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [countryIdCache, setCountryIdCache] = useState<string>(location.state == null ? '' : location.state.countryId);
  const [regionIdCache, setRegionIdCache] = useState<string>(location.state == null ? '' : location.state.regionId);
  const [freewordCache, setFreewordCache] = useState<string>(location.state == null ? '' : location.state.freeword);
  const [periodCache, setPeriodCache] = useState<string>('');
  const [salaryCache, setSalaryCache] = useState<string>('1');

  // NOTE: コンポーネントのrefが非推奨のため、親要素をリスナーとして使う必要がある
  const infScrollContRef = useRef<HTMLDivElement>(null);

  const processing = useRef(false);

  const loadMore = (page: number) => {
    getJobs([], countryIdCache, regionIdCache, freewordCache, periodCache, salaryCache, page).then((response) => {
      // 処理中フラグを折る
      processing.current = false;

      setJobs([...jobs, ...response]);
      setHasMore(response.length > 0);
    });
  };

  const onSubmit = async (data: SearchJobFormData) => {
    // 多重クリック禁止
    if (processing.current) return;
    // 処理中フラグを立てる
    processing.current = true;

    if (infScrollContRef?.current) {
      // HACK: 根本的にライブラリとESLintの相性が悪く、型にpageLoadedが存在しないためエラーになるが、動作中存在するので無視するしかない。
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      infScrollContRef.current.pageLoaded = 1;
    }

    getJobs([], data.countryId, data.regionId, data.freeword, data.period, data.salary).then((response) => {
      // 処理中フラグを折る
      processing.current = false;

      setJobs(response);
      setHasMore(response.length > 0);
    });

    setCountryIdCache(data.countryId);
    setRegionIdCache(data.regionId);
    setFreewordCache(data.freeword);
    setPeriodCache(data.period);
    setSalaryCache(data.salary);
  };

  return (
    <main id={styles.main}>
      <div className={styles.search}>
        <SearchJob onSubmit={onSubmit} hasSubColumns />
      </div>
      <div className={styles.mainCont}>
        <div>
          <h2>求人検索結果</h2>
          <div className={styles.infiniteCont} ref={infScrollContRef}>
            <InfiniteScroll
              pageStart={0}
              loadMore={loadMore}
              hasMore={hasMore}
              loader={
                <div key="is-loader" className={styles.loadingCircle}>
                  <CircularProgress size={60} />
                </div>
              }
              getScrollParent={() => infScrollContRef.current}
            >
              <JobListSection jobs={jobs} loadingForPrepare={false} />
            </InfiniteScroll>
          </div>
        </div>
      </div>
      <PageTop />
    </main>
  );
};

export default JobList;

import React, { FC, useRef, useState, useEffect, useCallback } from 'react';
import { Control, useController, UseFormRegisterReturn } from 'react-hook-form';
import { Option } from 'types';
import styles from './SelectDropdown.module.scss';

interface OptionsStyle {
  top: string;
  width: string;
}

type Props = {
  options: Option[];
  className?: string;
  style?: string;
  useFormRegisterReturn?: UseFormRegisterReturn;
  control?: Control<any>;
  updateRef?: any;
};

const SelectDropdown: FC<Props> = ({ className, style, options, useFormRegisterReturn, control, updateRef }) => {
  const { field } = useController({
    name: useFormRegisterReturn?.name as string,
    control,
  });

  const containerRef = useRef<HTMLDivElement>(null);
  const selectRef = useRef<HTMLSelectElement>(null);
  const selectedOptionRef = useRef<HTMLButtonElement>(null);
  const optionsRef = useRef<HTMLUListElement>(null);

  const [selectedOption, setSelectedOption] = useState<number>(
    (() => {
      for (let i = 0; i < options.length; i += 1) {
        if (options[i].id === field.value) return i;
      }
      return 0;
    })(),
  );
  const [optionsStyle, setOptionsStyle] = useState<OptionsStyle>({ top: '0', width: '0' });
  const [activate, setActivate] = useState<boolean>(false);

  const onClickSelectedOption = () => {
    const target = selectedOptionRef.current;
    setOptionsStyle({ top: `${target?.offsetHeight}px`, width: `${target?.offsetWidth}px` });
    setActivate(!activate);

    // 表示されたら選択項目にフォーカスを移動する
    if (optionsRef.current) {
      new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0) {
            const option = optionsRef.current?.querySelector(
              `li[data-index='${selectedOption}'] button`,
            ) as HTMLButtonElement;
            option?.focus();
            observer.disconnect();
          }
        });
      }).observe(optionsRef.current);
    }
  };

  const onKeydownSelectedOption = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    const moveIndex = (index: number) => {
      if (typeof options[index] !== 'undefined') {
        field.onChange(options[index].id);
        setSelectedOption(index);
      }
      event.preventDefault();
      event.stopPropagation();
    };

    switch (event.code) {
      case 'Enter':
        event.currentTarget.click();
        event.preventDefault();
        event.stopPropagation();
        break;
      case 'ArrowUp': {
        const prevIndex = selectedOption - 1;
        moveIndex(prevIndex);
        break;
      }
      case 'ArrowDown': {
        const nextIndex = selectedOption + 1;
        moveIndex(nextIndex);
        break;
      }
      default:
        break;
    }

    return event;
  };

  const onClickOption = (event: React.MouseEvent<HTMLButtonElement>) => {
    selectedOptionRef.current?.focus();
    field.onChange(event.currentTarget.value);
    setSelectedOption(Number(event.currentTarget.dataset.index));
    setActivate(false);
  };

  const onKeydownOption = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    const moveIndex = (index: number) => {
      if (typeof options[index] !== 'undefined') {
        const btn = optionsRef.current?.querySelector(`[data-index='${index}'] button`) as HTMLButtonElement;
        btn.focus();
      }
      event.preventDefault();
      event.stopPropagation();
    };

    switch (event.code) {
      case 'Enter':
        event.currentTarget.click();
        event.preventDefault();
        event.stopPropagation();
        break;
      case 'ArrowUp': {
        const prevIndex = Number(event.currentTarget.dataset.index) - 1;
        moveIndex(prevIndex);
        break;
      }
      case 'ArrowDown': {
        const nextIndex = Number(event.currentTarget.dataset.index) + 1;
        moveIndex(nextIndex);
        break;
      }
      default:
        break;
    }

    return event;
  };

  const onBlurSelectDropdown = (event: React.FocusEvent<HTMLButtonElement>) => {
    const relatedTarget = event.relatedTarget as HTMLElement;
    if (
      relatedTarget &&
      (selectedOptionRef.current?.contains(relatedTarget) || optionsRef.current?.contains(relatedTarget))
    ) {
      return;
    }

    setActivate(false);
  };

  const updateValue = useCallback(
    (value: string) => {
      field.onChange(value);
      setSelectedOption(
        (() => {
          for (let i = 0; i < options.length; i += 1) {
            if (options[i].id === value) return i;
          }
          return 0;
        })(),
      );
    },
    [field, options],
  );

  useEffect(() => {
    if (typeof updateRef !== 'function') return;

    updateRef(updateValue);
  }, [updateRef, updateValue]);

  return (
    <div
      className={`${className} ${styles.selectDropdownCont} ${style ? styles[`style-${style}`] : ''}`}
      ref={containerRef}
    >
      <select {...useFormRegisterReturn} {...field} ref={selectRef}>
        {options.map((option) => (
          <option key={`${useFormRegisterReturn?.name}-opt-${option.id}`} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
      <button
        type="button"
        className={`${styles.selectedOption} ${activate ? styles.activate : ''}`}
        onClick={onClickSelectedOption}
        onKeyDown={onKeydownSelectedOption}
        onBlur={onBlurSelectDropdown}
        ref={selectedOptionRef}
      >
        {options[selectedOption].name}
      </button>
      <ul className={styles.options} style={{ top: optionsStyle.top, width: optionsStyle.width }} ref={optionsRef}>
        {options.map((option, index) => (
          <li key={`${useFormRegisterReturn?.name}-dd-opt-${option.id}`} data-index={index}>
            <button
              type="button"
              className={`${field.value === option.id ? styles.selected : ''}`}
              data-index={index}
              value={option.id}
              onClick={onClickOption}
              onKeyDown={onKeydownOption}
              onBlur={onBlurSelectDropdown}
            >
              {option.name}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

SelectDropdown.defaultProps = {
  className: undefined,
  style: undefined,
  useFormRegisterReturn: undefined,
  control: undefined,
  updateRef: undefined,
};

export default SelectDropdown;

import { FC } from 'react';
import { Link } from 'react-router-dom';
import { getCookie, setCookie } from 'typescript-cookie';
import styles from './JobCard.module.scss';

type Props = {
  isAds: boolean;
  id: string;
  companyName: string;
  title: string;
  salary: string[];
  closeDate: string;
  url: string;
};

const JobCard: FC<Props> = ({ isAds, id, companyName, title, salary, closeDate, url }) => {
  const saveVisited = isAds
    ? () => {
        const visitedAdsOld = getCookie('visitedAds') ?? '';
        let visitedAds = visitedAdsOld === '' ? [] : visitedAdsOld.split(',');
        visitedAds = visitedAds.filter(function f(v) {
          return v !== id;
        });
        visitedAds.unshift(id);
        setCookie('visitedAds', visitedAds.join(','));
      }
    : () => {
        /* 何もしない */
      };

  return (
    <Link className={styles.jobCard} to={url} target="_blank" onClick={saveVisited}>
      <h3>
        <span className={styles.isPC}>{title.length <= 40 ? title : `${title.substring(0, 40)}...`}</span>
        <span className={styles.isSP}>{title.length <= 18 ? title : `${title.substring(0, 18)}...`}</span>
      </h3>
      <div>
        <div className={styles.basicInfo}>
          <ul className={styles.tags}>{/* タグ未実装 */}</ul>
          <div className={styles.salary}>
            <span className={styles.em}>
              {salary[0]}-{salary[1]}
            </span>
            万円
          </div>
        </div>
        <hr />
        <div className={styles.subInfo}>
          <div style={{ flexGrow: 1 }}>
            <span className={styles.isPC}>
              {companyName.length <= 30 ? companyName : `${companyName.substring(0, 30)}...`}
            </span>
            <span className={styles.isSP}>
              {companyName.length <= 20 ? companyName : `${companyName.substring(0, 20)}...`}
            </span>
          </div>
          <div>掲載終了日：{closeDate}</div>
        </div>
      </div>
    </Link>
  );
};

export default JobCard;
